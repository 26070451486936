import React from "react";
import PropTypes from "prop-types";
import { getSrc } from "gatsby-plugin-image"
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Content, { HTMLContent } from "../components/Content";
import Back from "../components/Back";
import RelatedPosts from "../components/RelatedPosts";
import HashtagHidden from "../components/HashtagHidden";

// eslint-disable-next-line
export const ArticlePostTemplate = ({
  content,
  contentComponent,
  description,
  image,
  title,
  related,
  hashtags
}) => {
  const PostContent = contentComponent || Content;
  return (
      <>
        <section>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description ?? `Article on Kamsa - ${title}`} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="HRLola"/>
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description ?? `Article on Kamsa - ${title}`}/>
            <meta name="twitter:image" content={"https://www.kamsa.co" + getSrc(image)} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description ?? `Article on Kamsa - ${title}`}/>
            <meta property="og:image" content={"https://www.kamsa.co" + getSrc(image)} />
            <meta property="og:image:width" content="300" />
            <meta property="og:image:height" content="300" />
          </Helmet>
        </section>
        <section className="section insights-container">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-8">
                <div className="block has-text-centered my-6">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image,
                      alt: `featured image thumbnail for post ${title}`,
                    }}
                  />
                </div>
                <div className="block has-text-centered mb-6 px-6 no-padding-mobile">
                  <h2 className="subtitle is-h2">{ title }</h2>
                  { description ?? <h4 className="is-h4">{ description }</h4> }
                </div>

                <PostContent content={content} />

                <div className="block has-text-centered">
                  <a className="button is-link button-demo" href="/request-demo">
                    Book a Demo
                  </a>
                </div>

                <div className="block has-text-centered back-to-insights">
                  <Back
                    title="Insights"
                    url="/insights"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section mb-6 insights-container">
          <div className="container px-6">
            <RelatedPosts related={related} />
          </div>
        </section>
        <HashtagHidden hashtags={hashtags} />
      </>
  );
};

ArticlePostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  related: PropTypes.array,
  hashtags: PropTypes.array,
};

const ArticlePost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data
  const { allPosts } = pageContext

  const recentPosts = allPosts.slice(0, 4).filter(elem => elem.title !== post.frontmatter.title)
  let related = recentPosts.slice(0, 3)
  const localRelated = post.frontmatter.relatedPosts ?? []
  if (localRelated && localRelated.length) {
    const localRelatedFormatted = allPosts.filter(elem => localRelated.includes(elem.title))
    const recentPostsMixin = recentPosts.filter(elem => !localRelated.includes(elem.title))
        .slice(0, 3 - localRelatedFormatted.length)
    related = localRelatedFormatted.concat(recentPostsMixin)
  }

  return (
    <Layout pageImage={post.frontmatter.featuredimage}>
      <ArticlePostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        image={post.frontmatter.featuredimage}
        title={post.frontmatter.title}
        related={related}
        hashtags={post.frontmatter.hashtags ?? []}
      />
    </Layout>
  );
};

ArticlePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ArticlePost;

export const pageQuery = graphql`
  query ArticlePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        relatedPosts
        hashtags {
          title
        }
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`;
