import React from 'react'
import PropTypes from "prop-types";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { Link } from "gatsby";

const RelatedPosts = ({ related }) => {
    return(
        <>
            <div className="columns is-multiline has-text-centered">
                <div className="column is-12 mb-6">
                    <div className="block">
                        <h1 className="title is-h2">Related Insights</h1>
                    </div>
                </div>
                {
                    related.map((elem, index) => {
                        return (
                            <div className="column is-4" key={index}>
                                <Link to={elem.slug}>
                                    <div className="block">
                                        <PreviewCompatibleImage
                                            imageInfo={{
                                                image: elem.image,
                                                alt: `featured image thumbnail for post ${elem.title}`,
                                            }}
                                        />
                                    </div>
                                    <div className="block">
                                        <h2 className="is-size-5">
                                            {elem.title}
                                        </h2>
                                    </div>
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

RelatedPosts.propTypes = {
  related: PropTypes.array,
};

export default RelatedPosts